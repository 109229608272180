// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Products from "./components/Products";
import Technology from "./components/Technology";
import Events from "./components/Events";
import Faqs from "./components/Faqs";
import Header from "./components/Header";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer"; // Import the Footer component
import Breweryandbeverage from "./components/Breweryandbeverage";
import Dairy from "./components/Dairy";
import Polutry from "./components/poultry";
import Foodandsnacks from "./components/Foodandsnacks";
import HomeCleaning from "./components/HomeCleaning";
import AutoMobile from "./components/AutoMobile";
// src/index.js or src/App.js

function App() {
  return (
    <Router>
      <div>
        <Header /> {/* Use the Header component */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/events" element={<Events />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/breweryandbeverage" element={<Breweryandbeverage />} />
          <Route path="/dairy" element={<Dairy />} />
          <Route path="/polutry" element={<Polutry />} />
          <Route path="/foodandsnacks" element={<Foodandsnacks />} />
          <Route path="/homecleaning" element={<HomeCleaning />} />
          <Route path="/automobile" element={<AutoMobile />} />

          {/* Add more routes as needed */}
        </Routes>
        <Footer /> {/* Use the Footer component */}
      </div>
    </Router>
  );
}

export default App;
