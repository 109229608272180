import React from "react";
import { useNavigate } from "react-router-dom";
import "./TechnologyContent.css";
import bevrages from "../images/food & beverages industry.png";
import dairy from "../images/dairy process industry.jpg";
import Poultry from "../images/poultry.jpg";
import food from "../images/food.jpg";
import automobile from "../images/autombile Cleaning.jpg";
import homecelaing from "../images/Homecleningimage.jpg";

const TechnologyItem = ({ title, description, imageSrc, imageAlt, route }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route);
  };

  return (
    <div
      className="technology-item"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div className="technology-image-container">
        <img src={imageSrc} alt={imageAlt} className="technology-image" />
      </div>
      <div className="technology-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Technologies = () => {
  const technologies = [
    {
      title: "Home & House keeping Cleaning",
      description:
        "Professional home cleaning products to keep your living space spotless and comfortable.",
      imageSrc: homecelaing,
      imageAlt: "A clean and organized living room",
      route: "/homecleaning",
    },

    {
      title: "Dairy",
      description:
        "Offering advanced cleaning solutions to ensure the safety and quality of dairy products.",
      imageSrc: dairy,
      route: "/dairy",
    },
    {
      title: "Poultry",
      description:
        "Supplying effective cleaning products to uphold rigorous safety standards in poultry processing.",
      imageSrc: Poultry,
      route: "/polutry",
    },
    {
      title: "Brewery & Beverage",
      description:
        "Providing specialized cleaning products to maintain hygiene and quality in beverage production.",
      imageSrc: bevrages,
      route: "/breweryandbeverage",
    },
    {
      title: "Food & Snacks",
      description:
        "Delivering cleaning solutions that support the production of healthy and sustainable food products.",
      imageSrc: food,
      route: "/foodandsnacks",
    },
    {
      title: "Automobile Cleaning",
      description:
        "High-quality products to keep your vehicle spotless and well-maintained.",
      imageSrc: automobile,
      imageAlt: "A clean and shiny car",
      route: "/automobile",
    },
  ];

  return (
    <section className="technologies">
      <h2>Product Category</h2>
      <div className="technology-list">
        {technologies.map((technology, index) => (
          <TechnologyItem key={index} {...technology} />
        ))}
      </div>
    </section>
  );
};

export default Technologies;
