import React, { useState } from "react";
import "./Breweryandbeverage.css";
import image1 from "../images/300x300.png";
import image2 from "../images/300x300.png";
import image3 from "../images/300x300.png";
import image4 from "../images/300x300.png";
import image5 from "../images/300x300.png";
import image6 from "../images/300x300.png";
import image7 from "../images/300x300.png";
import image8 from "../images/300x300.png";
import image9 from "../images/300x300.png";
import image10 from "../images/300x300.png";
import image11 from "../images/300x300.png";
import image12 from "../images/300x300.png";
import image13 from "../images/300x300.png";
import image14 from "../images/300x300.png";
import image15 from "../images/300x300.png";
import image16 from "../images/300x300.png";
import image17 from "../images/300x300.png";

const allProducts = [
  {
    id: 1,
    name: "ChemX CIP 321",
    category: "BMC / MCC Cleaning",
    description:
      "Is a general purpose cleaner cum disinfectant for Plant Cleaning Applications in Dairy Industries. This product is an excellent combination of various cationic surfactants and sequestering agent which results in efficient soil removal and disinfection of the surface. Weight : 25kg, 50kg, 200kg",
    image: image1,
  },
  {
    id: 2,
    name: "ChemX Nuclean",
    category: "BMC / MCC Cleaning",
    description:
      "Nuclean has been developed for the current requirement of general cleaning & sanitation. is a combination of highly concentrated cleaner with foaming surfactant, which reduces the surface tension and cationic-based disinfectant for quick sanitation, so it works as a cleaner as well as a disinfectant. Nuclean is safe on various surfaces like: stainless steel, Aluminium, plastic, rubber, fibreglass and all materials utilized in food and dairy industries. Weight : 25kg, 50kg, 200kg",
    image: image2,
  },
  {
    id: 3,
    name: "ChemX CIP 6010",
    category: "BMC / MCC Cleaning",
    description:
      "CIP 6010 is liquid alkaline foamless cleaner developed for daily use in the Food Processing Industries, especially at Milk Collection & Processing Units and Sea Food Processing Industries. The product is a perfect blend of Non-Foaming Surfactants and Sodium Hypochlorite. The presence of surfactants offer wetting action and potassium hydroxide provides a strong detergent & descaling effect. Weight : 25kg, 50kg, 200kg",
    image: image3,
  },
  {
    id: 4,
    name: "Chmex Des (5 & 15%)",
    category: "BMC / MCC Cleaning",
    description:
      "Fast action sanitizer based on stabilised Hydrogen Peroxide and Peracetic Acid. It is highly effective against most of the microorganisms and viruses even in cold water. Peracetic Acid provides fast sanitizing and Hydrogen Peroxide renders the product suitable for flushing or immersion with better results when compared with Quarts, Phenols, Aldehydes & Halogens. Weight : 25kg, 50kg, 200kg",
    image: image4,
  },
  {
    id: 5,
    name: "ChemX CIP 50",
    category: "Cleaning In Place",
    description:
      "Has been developed for the current requirement of cleaning in the Food Processing Industries and can be applied as liquid cleaner for tanks, crates, cans, floor, trays and other process areas. CIP_ 50 is a combination of Non-Ionic surfactants, Sodium Hydroxide and Phosphonates. Non-Ionic surfactant reduces the surface tension, Sodium Hydroxide provides cleaning effect and Phosphates provide penetration effect. Weight : 25kg, 50kg, 200kg",
    image: image5,
  },
  {
    id: 6,
    name: "ChemX AD 101",
    category: "Cleaning In Place",
    description:
      "Has been developed for current requirement of cleaning & sanitation in the food, beverages & dairy industries. Alkaline caustic additive which is a combination of highly concentrated cleaner for CIP usage reduces the caustic consumption with excellent cleaning result. Weight : 25kg, 50kg, 200kg",
    image: image6,
  },
  {
    id: 7,
    name: "ChemX AD DS",
    category: "Cleaning In Place",
    description:
      "Acidic non – foaming odourless liquid de Scaler through CIP as well as manual in hot or cold condition highly effective for removing milk stone. AD– DS is high class scale remover from external and internal surface with accelerating quality of cleaning. Weight : 25kg, 50kg, 200kg",
    image: image7,
  },
  {
    id: 8,
    name: "ChemX DES 5% & 15%",
    category: "Disinfectent & Sanitation",
    description:
      "ChemX DES 5% & 15% is a special fast action sanitizer based on stabilized hydrogen peroxide and peracetic acid. Chemx Des –PA 5% &15% is a highly effective against all type of micro-organisms and viruses even in cold water. Peracetic acid provides fast sanitizing & Hydrogen Peroxide renders the product suitable for flushing or immersion. Weight : 25kg, 50kg, 200kg",
    image: image8,
  },
  {
    id: 9,
    name: "ChemX DES SilvoX",
    category: "Disinfectent & Sanitation",
    description:
      "Des Silvox is a specially developed cold disinfectant for food and dairy industries for quick & cold sterilization requirement. It is a combination of highly concentrate hydrogen peroxide and silver cations. Des Silvox is a totally new generation odourless, tasteless and non-toxic. Disinfectant no effect on pH value as well as safe on various surfaces as non-corrosive effect. : 25kg, 50kg, 200kg",
    image: image9,
  },
  {
    id: 10,
    name: "Chemx DES QUAT",
    category: "Disinfectent & Sanitation",
    description:
      "ChemX Quat Quat is a general-purpose disinfectant for Plant Cleaning Applications in Food & Beverage Industries. This product is an excellent combination of various cationic surfactants and sequestering agent which results in efficient soil removal and disinfection of the surface. Weight : 25kg, 50kg, 200kg",
    image: image10,
  },
  {
    id: 11,
    name: "ChemX TA ( Ecoday)",
    category: "Milk Analyazer Cleaning Solution",
    description:
      "Alkaline cleaner is liquid alkaline foamless cleaner developed for daily use in the Food Processing Industries, especially at Milk Collection. The product is a perfect blend of Non-Foaming Surfactants and Sodium Hypochlorite. The presence of surfactants offers wetting action and potassium hydroxide provides a strong detergent & descaling effect. It is very effective for use in daily cleaning applications of Processing Equipment’s. Weight : 25kg, 50kg, 200kg",
    image: image11,
  },
  {
    id: 12,
    name: "ChemX TS ( Ecoweek)",
    category: "Milk Analyazer Cleaning Solution",
    description:
      "ACIDIC CLEANER is acidic nature foamless cleaner developed for daily use in food processing industries, especially at milk collection and processing units. It is a perfect blend of inorganic acids and non foaming surfactants. It is very effective for use in daily cleaning applications of milk collection and processing equipment’s like milking machine, bulk milk coolers etc. Weight : 25kg, 50kg, 200kg",
    image: image12,
  },
  {
    id: 13,
    name: "ChemX Power ( Ecopower)",
    category: "Milk Analyazer Cleaning Solution",
    description:
      "Power Cleaner is cleaner developed for daily use in food processing industries, especially at milk collection and processing units. It is very effective for use in cleaning of Milk analysing equipment’s. Weight : 25kg, 50kg, 200kg",
    image: image13,
  },

  {
    id: 14,
    name: "ChemX Foam 100",
    category: "Open Plant / Genral Purpose Cleaner",
    description:
      "Is a perfect blend of potassium hydroxide, high foaming surfactants and hypochlorite. The presence of surfactants offers an improved sanitizing action and potassium hydroxide provides a strong detergent effect. It is very effective for use on a wide variety of applications ranging from effective removal and prevention of food soiling, scale deposits and oil stains. Weight : 25kg, 50kg, 200kg",
    image: image14,
  },
  {
    id: 15,
    name: "ChemX Nuclean",
    category: "Open Plant / Genral Purpose Cleaner",
    description:
      "Has been developed for the current requirement of cleaning in the Food & Beverage and Dairy Industries. Nuclean can be applied as liquid cleaner for tanks, crates, cans, floor, trays and other process areas. Nuclean is a combinatio of Anionic, Non-Ionic base surfactants and Sequesting agents. Non-Ionic surfactant reduces the surface tension, Anionic surfactant gives cleaning effect and Sequesting Agent results in descaling. Nuclean is a neutral pH product and it is safe on various surfaces like Stainless Steel, Aluminium, Plastic, Rubber, Fibreglass. Weight : 25kg, 50kg, 200kg",
    image: image15,
  },
  {
    id: 16,
    name: "ChemX Hiclean",
    category: "Open Plant / Genral Purpose Cleaner",
    description:
      "Hiclean has been developed for the current requirement of Industrial & Institutional Cleaning in the Food & Beverage Industries. In the Industrial Applications, HICLEAN can be applied as cleaner for tanks, crates, cans, floor, trays and other process areas. HICLEAN is safe on various surfaces like Stainless Steel, Aluminium, Plastic, Rubber, Fibreglass. Weight : 25kg, 50kg, 200kg",
    image: image16,
  },
  {
    id: 17,
    name: "ChemX Foam 200",
    category: "Open Plant / Genral Purpose Cleaner",
    description:
      "Chemx Foam 200 is a phosphoric acid based foam cleaner with good fat solving properties. The product is designed for cleaning of acid proof machinery, walls and floors in the beverage and food industry. ChemX Foam 200 removes mineral salt deposits and organic soils like protein and fat. It is normally applied as foam, but can also be used for manual or soak applications. ChemX Foam 200 produces hygienic clean and sparkling surface. Weight : 25kg, 50kg, 200kg",
    image: image17,
  },
];

const categories = [
  "All",
  "BMC / MCC Cleaning",
  "Cleaning In Place",
  "Disinfectent & Sanitation",
  "Open Plant / Genral Purpose Cleaner",
  "Milk Analyazer Cleaning Solution",
];

const Breweryandbeverage = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [products, setProducts] = useState(allProducts);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    if (category === "All") {
      setProducts(allProducts);
    } else {
      setProducts(
        allProducts.filter((product) => product.category === category)
      );
    }
  };

  return (
    <div className="page-container">
      <div className="title">
        <h1>Dairy Products</h1>
        <div className="categories">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`category-button ${
                activeCategory === category ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      {products.map((product, index) => (
        <div
          key={product.id}
          className={`product-row ${index % 2 === 0 ? "normal" : "reversed"}`}
        >
          <div className="product-image-container">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
          </div>
          <div className="product-description">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Breweryandbeverage;
