import React, { useState } from "react";
import "./Breweryandbeverage.css";
import image1 from "../images/300x300.png";
import image2 from "../images/300x300.png";
import image3 from "../images/300x300.png";
import image4 from "../images/300x300.png";
import image5 from "../images/300x300.png";
import image6 from "../images/300x300.png";
import image7 from "../images/300x300.png";
import image8 from "../images/300x300.png";
import image9 from "../images/300x300.png";
import image10 from "../images/300x300.png";
import image11 from "../images/300x300.png";

const allProducts = [
  {
    id: 1,
    name: "ChemX BW 1100",
    category: "Bottle Washing Additive",
    description:
      "ChemX BW 1100 is a heavy duty, primary additive to caustic soda solution, specially designed for bottle washing of returnable glass bottles with difficult to clean mould pads and heavily soiled bottles. It is formulated with a unique combination of surfactants and organic acids to ensure effective cleaning performance and soil suspending properties to deliver clean bottles. The additive enhances wetting of paper labels and aluminium foils for efficient removal from bottles. It can be used for cleaning of returnable beverage and beer bottles. It can also be used for wide range of applications as an additive to caustic within Beverage, Brewing, Dairy, Food Processing and Pharmaceutical industries. Weight : 25kg, 50kg, 200kg",
    image: image1,
  },
  {
    id: 2,
    name: "ChemX BW BL",
    category: "Bottle Washing Additive",
    description:
      "Is a liquid concentrate specially developed as a booster for caustic bath of bottle washer. This product is an excellent wetting agent and perfect blend of non-ionic surfactants, resulting in excellent hard pad and label removal during glass bottle washing process. Increase the wetting of metallic label which is not easily removed in normal condition. Weight : 25kg, 50kg, 200kg",
    image: image2,
  },
  {
    id: 3,
    name: "ChemX BW 800",
    category: "Bottle Washing Additive",
    description:
      "ChemX BW 800 is an additive especially for bottle cleaning based on complexing agents and dispersants which Enhances bottle shine. ChemX BW 800 prevents the deposit of aluminium stone and can be used for cleaning of glass-, PET-, PEN and polycarbonate-bottles. Weight : 25kg, 50kg, 200kg",
    image: image3,
  },
  {
    id: 4,
    name: "ChemX LUBE 853",
    category: "Conveyor Chain Lubricants",
    description:
      "Is a soap-based lubricant in liquid form with cleaning and germicidal properties for chain conveyors in food & beverage / brewery industries. It is a specially developed product for transportation of the goods in glass bottles. Cleaning and sanitation of conveyor belt along with special corrosion inhibitors. Suitable for usage with all types of conveyor belts like stainless steel, chromium steel and plastic.Weight : 25kg, 50kg, 200kg",
    image: image4,
  },
  {
    id: 5,
    name: "Chemx Lube 846",
    category: "Conveyor Chain Lubricants",
    description:
      "It is a synthetic conveyor chain lubricant in liquid form with cleaning and germicidal properties for chain conveyors in Food & Beverage Industries. It is a specially developed synthetic lubricant product for transportation of the goods in glass bottles, cans, cartoons and PET bottles Cleaning and sanitation of conveyor belt along with special corrosion inhibitors. low foaming characteristic reduces the foam building on conveyor. Weight : 25kg, 50kg, 200kg",
    image: image5,
  },
  {
    id: 6,
    name: "ChemX Lube SD",
    category: "Conveyor Chain Lubricants",
    description:
      "Is a semidry conveyor chain lubricant in liquid form with cleaning and germicidal properties for chain conveyors in Food & Beverage Industries. It is a specially developed synthetic lubricant product for transportation of the goods in glass bottles, cans and PET bottles. Suitable for usage with all types of conveyor belts like stainless steel, chromium steel and plastic. Very low foaming characteristic reduces the foam building on conveyor. Excellent results for any water hardness eliminating need of softener installation and maintenance. Economical dosage with very low water consumption. Weight : 25kg, 50kg, 200kg",
    image: image6,
  },
  {
    id: 7,
    name: "ChemX Dry Tech",
    category: "Conveyor Chain Lubricants",
    description:
      "Dry Tech is a concentrated and high penetrating conveyor chain lubricant in liquid form with applications in food & beverage / brewery industries. It is a specially designed product to be used as a concentrate with no water dilution required and it is suitable for transportation of the goods in PET bottles and cans on flat top conveyor chains. Direct use as a concentrate which eliminates the need to spray high volume of water and soluble lubricant, resulting in zero effluent discharge. Weight : 25kg, 50kg, 200kg",
    image: image7,
  },
  {
    id: 8,
    name: "ChemX AD 101",
    category: "Cleaning In Place (CIP)",
    description:
      "Has been developed for current requirement of cleaning & sanitation in the food, beverages & dairy industries. Alkaline caustic additive which is a combination of highly concentrated cleaner for CIP usage reduces the caustic consumption with excellent cleaning result. Weight : 25kg, 50kg, 200kg",
    image: image8,
  },
  {
    id: 9,
    name: "ChemX AD DS",
    category: "Cleaning In Place (CIP)",
    description:
      "Acidic non – foaming odourless liquid de Scaler through CIP as well as manual in hot or cold condition highly effective for removing milk stone. AD– DS is high class scale remover from external and internal surface with accelerating quality of cleaning.. Weight : 25kg, 50kg, 200kg",
    image: image9,
  },
  {
    id: 10,
    name: "Chemx CIP 50",
    category: "Cleaning In Place (CIP)",
    description:
      "CIP 50 has been developed for the current requirement of cleaning in the Food Processing Industries and can be applied as liquid cleaner for tanks, crates, cans, floor, trays and other process areas. CIP _ 50 is a combination of Non-Ionic surfactants, Sodium Hydroxide and Phosphonates. Non-Ionic surfactant reduces the surface tension, Sodium Hydroxide provides cleaning effect and Phosphates provide penetration effect. Weight : 25kg, 50kg, 200kg",
    image: image10,
  },
  {
    id: 11,
    name: "ChemX Foam NU",
    category: "OPC (Open Plant Cleaning)",
    description:
      "ChemX Foam NU is foam cleaning agent developed for daily use in the Food and Beverage Industries. The product is a perfect blend of high foaming surfactants and EDTA. The presence of surfactants offers an improved sanitizing action and EDTA provides a strong detergent effect. Weight : 25kg, 50kg, 200kg",
    image: image11,
  },
];

const categories = [
  "All",
  "Bottle Washing Additive",
  "Conveyor Chain Lubricants",
  "Cleaning In Place (CIP)",
  "OPC (Open Plant Cleaning)",
];

const Breweryandbeverage = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [products, setProducts] = useState(allProducts);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    if (category === "All") {
      setProducts(allProducts);
    } else {
      setProducts(
        allProducts.filter((product) => product.category === category)
      );
    }
  };

  return (
    <div className="page-container">
      <div className="title">
        <h1>Brewery and Beverage Products</h1>
        <div className="categories">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`category-button ${
                activeCategory === category ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      {products.map((product, index) => (
        <div
          key={product.id}
          className={`product-row ${index % 2 === 0 ? "normal" : "reversed"}`}
        >
          <div className="product-image-container">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
          </div>
          <div className="product-description">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Breweryandbeverage;
