import React, { useState } from 'react';
import './FAQ.css';

const faqs = [
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of services including residential cleaning, commercial cleaning, and specialized cleaning services for various industries.'
  },
  {
    question: 'Do you use your own cleaning products?',
    answer: 'Yes, we use our own line of eco-friendly cleaning products that are safe for both the environment and your home.'
  },
  {
    question: 'How can I book a service?',
    answer: 'You can book a service through our website or by calling our customer service hotline. We offer flexible scheduling to meet your needs.'
  },
  {
    question: 'Are your cleaning staff trained and insured?',
    answer: 'Yes, all our cleaning staff are professionally trained and fully insured to ensure the highest quality of service and peace of mind for our clients.'
  },
  {
    question: 'What is your cancellation policy?',
    answer: 'You can cancel or reschedule your appointment up to 24 hours in advance without any penalty. Cancellations made within 24 hours of the appointment may incur a fee.'
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAccordion(index)}>
            {faq.question}
            <span className={`arrow ${activeIndex === index ? 'down' : 'right'}`}></span>
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;