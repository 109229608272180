import React, { useState } from "react";
import "./Breweryandbeverage.css";
import image1 from "../images/GlassCleaner.jpeg";
import image2 from "../images/ToiletCleaner.jpeg";
import image3 from "../images/FloorCleaner.jpeg";
import image4 from "../images/DishWasher.jpeg";
import image5 from "../images/HandWash.jpeg";
import image6 from "../images/Laundry.jpeg";

const allProducts = [
  {
    id: 1,
    name: "Glass Cleaner",
    category: "Cleaning Products",
    description:
      "Our Glass Cleaner is a powerful formula designed to remove dirt, grime, and fingerprints from glass surfaces, leaving them sparkling clean and streak-free. Suitable for windows, mirrors, and glass tables. Weight: 500ml, 1L",
    image: image1,
  },
  {
    id: 2,
    name: "Toilet Cleaner",
    category: "Cleaning Products",
    description:
      "This Toilet Cleaner is specially formulated to remove tough stains, limescale, and bacteria from your toilet bowl, ensuring a hygienic and fresh-smelling bathroom. Weight: 500ml, 1L",
    image: image2,
  },
  {
    id: 3,
    name: "Floor Cleaner",
    category: "Cleaning Products",
    description:
      "Our Floor Cleaner is a versatile solution that effectively cleans and disinfects various types of flooring, including tiles, hardwood, and laminate. It leaves a pleasant fragrance and a streak-free finish. Weight: 1L, 5L",
    image: image3,
  },
  {
    id: 4,
    name: "Dishwasher Detergent",
    category: "Cleaning Products",
    description:
      "This Dishwasher Detergent is designed to tackle tough grease and food residues, leaving your dishes sparkling clean. It is suitable for all types of dishwashers and ensures a spotless finish. Weight: 500g, 1kg",
    image: image4,
  },
  {
    id: 5,
    name: "Handwash",
    category: "Cleaning Products",
    description:
      "Our Handwash is a gentle yet effective formula that removes dirt and germs from your hands, leaving them clean and moisturized. Suitable for frequent use. Weight: 250ml, 500ml",
    image: image5,
  },
  {
    id: 6,
    name: "Laundry Detergent",
    category: "Cleaning Products",
    description:
      "Our Laundry Detergent is a powerful formula that removes stains and odors from your clothes, leaving them fresh and clean. Suitable for both machine and hand washing. Weight: 1kg, 2kg, 5kg",
    image: image6,
  },
];

const categories = [
  "All",
  // "Bottle Washing Additive",
  // "Conveyor Chain Lubricants",
  // "Cleaning In Place (CIP)",
  // "OPC (Open Plant Cleaning)",
];

const Breweryandbeverage = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [products, setProducts] = useState(allProducts);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    if (category === "All") {
      setProducts(allProducts);
    } else {
      setProducts(
        allProducts.filter((product) => product.category === category)
      );
    }
  };

  return (
    <div className="page-container">
      <div className="title">
        <h1>Automobile cleaning Products</h1>
        {/* <div className="categories">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`category-button ${
                activeCategory === category ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div> */}
      </div>
      {/* {products.map((product, index) => (
        <div
          key={product.id}
          className={`product-row ${index % 2 === 0 ? "normal" : "reversed"}`}
        >
          <div className="product-image-container">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
              style={{ height: "300px", width: "200px" }}
            />
          </div>
          <div className="product-description">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </div>
        </div>
      ))} */}
    </div>
  );
};

export default Breweryandbeverage;
