import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css"; // Import the CSS file for styling
import logo from "../images/logo.png";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <div className="top-header">
        <div>Email: info@protexive.in</div>
        <div>Phone: +91 9428630931</div>
      </div>
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="MyLogo" className="logo-image" />
          </Link>
        </div>

        {!menuOpen && (
          <button className="menu-toggle" onClick={toggleMenu}>
            &#9776; {/* Unicode character for hamburger icon */}
          </button>
        )}

        <nav className={`nav ${menuOpen ? "open" : ""}`}>
          {menuOpen && (
            <button className="menu-toggle" onClick={toggleMenu}>
              &#9776; {/* Unicode character for hamburger icon */}
            </button>
          )}
          <ul>
            <li className={isActive("/")}>
              <Link to="/">Home</Link>
            </li>
            <li className={isActive("/about-us")}>
              <Link to="/about-us">About Us</Link>
            </li>
            <li className={isActive("/products")}>
              <Link to="/products">Products</Link>
            </li>
            <li className={isActive("/events")}>
              <Link to="/events">Events</Link>
            </li>
            <li className={isActive("/faqs")}>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li className={isActive("/contact-us")}>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;
