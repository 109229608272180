import React, { useState } from "react";
import "./Breweryandbeverage.css";
import image1 from "../images/300x300.png";
import image2 from "../images/300x300.png";
import image3 from "../images/300x300.png";
import image4 from "../images/300x300.png";
import image5 from "../images/300x300.png";
import image6 from "../images/300x300.png";
import image7 from "../images/300x300.png";
import image8 from "../images/300x300.png";
import image9 from "../images/300x300.png";
import image10 from "../images/300x300.png";
import image11 from "../images/300x300.png";
import image12 from "../images/300x300.png";

const allProducts = [
  {
    id: 1,
    name: "ChemX DES QUAT",
    category: "Disinfection Chemicals",
    description:
      "Is a general purpose cleaner cum disinfectant for Plant Cleaning Applications in Dairy Industries. This product is an excellent combination of various cationic surfactants and sequestering agent which results in efficient soil removal and disinfection of the surface. Weight : 25kg, 50kg, 200kg",
    image: image1,
  },
  {
    id: 2,
    name: "ChemX DES 5 % & 15%",
    category: "Disinfection Chemicals",
    description:
      "Nuclean has been developed for the current requirement of general cleaning & sanitation. is a combination of highly concentrated cleaner with foaming surfactant, which reduces the surface tension and cationic-based disinfectant for quick sanitation, so it works as a cleaner as well as a disinfectant. Nuclean is safe on various surfaces like: stainless steel, Aluminium, plastic, rubber, fibreglass and all materials utilized in food and dairy industries. Weight : 25kg, 50kg, 200kg",
    image: image2,
  },
  {
    id: 3,
    name: "ChemX DES 680",
    category: "Disinfection Chemicals",
    description:
      "CIP 6010 is liquid alkaline foamless cleaner developed for daily use in the Food Processing Industries, especially at Milk Collection & Processing Units and Sea Food Processing Industries. The product is a perfect blend of Non-Foaming Surfactants and Sodium Hypochlorite. The presence of surfactants offer wetting action and potassium hydroxide provides a strong detergent & descaling effect. Weight : 25kg, 50kg, 200kg",
    image: image3,
  },
  {
    id: 4,
    name: "ChemX DES PQG",
    category: "Disinfection Chemicals",
    description:
      "Fast action sanitizer based on stabilised Hydrogen Peroxide and Peracetic Acid. It is highly effective against most of the microorganisms and viruses even in cold water. Peracetic Acid provides fast sanitizing and Hydrogen Peroxide renders the product suitable for flushing or immersion with better results when compared with Quarts, Phenols, Aldehydes & Halogens. Weight : 25kg, 50kg, 200kg",
    image: image4,
  },
  {
    id: 5,
    name: "ChemX DES 3002",
    category: "Disinfection Chemicals",
    description:
      "QAC based, It is a multipurpose sanitizer with optimized blend of glutaraldehyde for use in Poultry/Hatchery. The product has a broad spectrum of activity against gram positive and gram negative bacteria, yeasts and fungi. Weight : 25kg, 50kg, 200kg",
    image: image5,
  },
  {
    id: 6,
    name: "ChemX Nuclean",
    category: "Manual Cleaning",
    description:
      "Concentrated Neutral Cleaner used for all general-purpose cleaning in Farm/Hatcheries. It is highly efficient cleaner for floor, walls, equipments & other washable surfaces.It contains powerful cleaning agents which quickly removes soils without damaging the surfaces when used for daily cleaning. Weight : 25kg, 50kg, 200kg",
    image: image6,
  },
  {
    id: 7,
    name: "ChemX Foam 100",
    category: "Foam Cleaning",
    description:
      "Concentrated liquid alkaline chlorinated foam cleaner for use in Poultry/Hatchery. It is used for cleaning floors, walls, cutting tables, packaging machines, conveyors and other equipments. It provides chlorine for complete sanitizing together with high concentration of powerful detergents to remove fats, oils, blood, protein, grease residues and other contaminants. Weight : 25kg, 50kg, 200kg",
    image: image7,
  },
  {
    id: 8,
    name: "ChemX Foam 200",
    category: "Foam Cleaning",
    description:
      "It is a highly concentrated phosphoric acid-based cleaner with foaming boosters specially formulated for use in Poultry/Hatchery for effective cleaning and descaling. It removes food soils, protein deposits and hard water scales from all types of surfaces like floor, walls, cutting tables, packaging machines, conveyors and other equipments. Weight : 25kg, 50kg, 200kg",
    image: image8,
  },
  {
    id: 9,
    name: "ChemX Foam NU",
    category: "Foam Cleaning",
    description:
      "Concentrated neutral multipurpose foam cleaning liquid for cleaning of all types of surfaces like floor, walls, cutting tables, packaging machines, conveyors and other equipments. Weight : 25kg, 50kg, 200kg",
    image: image9,
  },
  {
    id: 10,
    name: "ChemX DES SilvoX",
    category: "Disinfectent & Sanitation",
    description:
      "Des Silvox is a specially developed cold disinfectant for food and dairy industries for quick & cold sterilization requirement. It is a combination of highly concentrate hydrogen peroxide and silver cations. Des Silvox is a totally new generation odourless, tasteless and non-toxic. Disinfectant no effect on pH value as well as safe on various surfaces as non-corrosive effect. : 25kg, 50kg, 200kg",
    image: image10,
  },
  {
    id: 11,
    name: "ChemX Hand Soap",
    category: "Hand Care",
    description:
      "Odorless & colorless Effective hygiene and sanitation of hands with appropriate moisturizer. Weight : 25kg, 50kg, 200kg",
    image: image11,
  },
  {
    id: 12,
    name: "ChemX H DES",
    category: "Hand Care",
    description:
      "Isopropyl alcohol-based hand sanitizer Waterless fast and effective hand sanitizer with antimicrobial action. Apply 3-5 ml to clean dry hands and massage thoroughly all over hand skin. Within 30 seconds skin will be dry as alcohol evaporates. Weight : 25kg, 50kg, 200kg",
    image: image12,
  },
];

const categories = [
  "All",
  "Disinfection Chemicals",
  "Manual Cleaning",
  "Foam Cleaning",
  "Hand Care",
];

const Breweryandbeverage = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [products, setProducts] = useState(allProducts);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    if (category === "All") {
      setProducts(allProducts);
    } else {
      setProducts(
        allProducts.filter((product) => product.category === category)
      );
    }
  };

  return (
    <div className="page-container">
      <div className="title">
        <h1>Poultry Products</h1>
        <div className="categories">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`category-button ${
                activeCategory === category ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      {products.map((product, index) => (
        <div
          key={product.id}
          className={`product-row ${index % 2 === 0 ? "normal" : "reversed"}`}
        >
          <div className="product-image-container">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
          </div>
          <div className="product-description">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Breweryandbeverage;
