import React from 'react';
import './Timeline.css';

const events = [
  {
    date: '2023-10-01',
    title: 'Company Anniversary',
    description: 'Celebrating the company\'s 10th anniversary.',
    image: 'https://via.placeholder.com/150'
  },
  {
    date: '2023-11-15',
    title: 'Product Launch',
    description: 'Launching our new product line.',
    image: 'https://via.placeholder.com/150'
  },
  {
    date: '2023-12-25',
    title: 'Christmas Party',
    description: 'Annual Christmas party for all employees.',
    image: 'https://via.placeholder.com/150'
  }
];

const Timeline = () => {
  return (
    <div className="timeline">
      <div className="timeline-line"></div>
      {events.map((event, index) => (
        <div key={index} className={`timeline-event ${index % 2 === 0 ? 'left' : 'right'}`}>
          <div className="timeline-date">{event.date}</div>
          <div className="timeline-content">
            <img src={event.image} alt={event.title} className="timeline-image" />
            <div className="timeline-text">
              <h3>{event.title}</h3>
              <p>{event.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;