import React, { useEffect } from "react";
import Slide1 from "../images/slide-1.jpg";
import Product1 from "../images/new-prod-1.jpeg";
import Product2 from "../images/new-prod-2.jpeg";
import Product3 from "../images/new-prod-3.jpeg";
import Product4 from "../images/new-prod-4.jpeg";
import Product5 from "../images/new-prod-5.jpeg";
import "animate.css";
import "../css/carousel.css";

const StaticSlide = () => {
  const textArray = [
    "Welcome to",
    "Protexive Enterprises",
    "The Perfect Chemistry - ",
    "Where Cleanliness meets",
    "Quality, Sustainability, and Affordability!",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const current = document.querySelector("input[name='slider']:checked");
      const next =
        current.nextElementSibling ||
        document.querySelector("input[name='slider']:first-of-type");
      if (next && next.type === "radio") {
        next.checked = true;
      } else {
        document.querySelector(
          "input[name='slider']:first-of-type"
        ).checked = true;
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="static-slide">
      <picture className="mobile-img">
        <source media="(min-width:1920px)" srcSet="slide-1-2560.jpg" />
        <source media="(max-width: 600px)" srcSet="./css/img/mob-1.jpeg" />
        <img alt="" className="d-block img-fluid imageprop" src={Slide1} />
      </picture>
      <div className="wave-container">
        <div className="wave"></div>
      </div>
      <div className="text-box carousel-caption d-md-block d-flex flex-column">
        {textArray.map((text, index) => (
          <p
            key={index}
            className={`${
              index === 0 || index === 1 ? "large-text" : "small-text"
            } animate__animated animate__fadeIn animate__delay-${index}s`}
          >
            {text}
          </p>
        ))}

        <div className="carousel-container">
          <input type="radio" name="slider" id="item-1" defaultChecked />
          <input type="radio" name="slider" id="item-2" />
          <input type="radio" name="slider" id="item-3" />
          <input type="radio" name="slider" id="item-4" />
          <input type="radio" name="slider" id="item-5" />
          <div className="cards">
            <label className="card" htmlFor="item-1" id="product-1">
              <img src={Product1} alt="Product 1" className="imgProducts" />
            </label>
            <label className="card" htmlFor="item-2" id="product-2">
              <img src={Product2} alt="Product 2" className="imgProducts" />
            </label>
            <label className="card" htmlFor="item-3" id="product-3">
              <img src={Product3} alt="Product 3" className="imgProducts" />
            </label>
            <label className="card" htmlFor="item-4" id="product-4">
              <img src={Product4} alt="Product 4" className="imgProducts" />
            </label>
            <label className="card" htmlFor="item-5" id="product-5">
              <img src={Product5} alt="Product 5" className="imgProducts" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticSlide;
