import React from "react";
import "./AboutUs.css";
import aboutus from "../images/aboutus.jpg";

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={aboutus} alt="Cleaning Services" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2>We bring sparkling clean to any space</h2>
            <p>
              Protexive Enterprises, (The Perfect Chemistry) established in
              2016, manufactures and supplies top-quality cleaning products for
              institutions, industries, and households under the brand Bright
              Bliss. We are committed to offering exceptional products at
              affordable prices while ensuring safety and ease of use.
            </p>
            <p>
              Our team of highly skilled professionals and quality experts is
              vital to our success, helping us stay ahead of market trends. Each
              product undergoes rigorous quality control before dispatch,
              ensuring it meets competitive market standards. Our
              customer-focused approach has quickly propelled us to a leading
              position in the industry.
            </p>
          </div>
        </div>
        <div className="vision-mission-section">
          <div className="vision">
            <div className="vision-image"></div>
            <div className="vision-text">
              <i className="fas fa-eye"></i>
              <h2>Vision</h2>
              <p>
                To become a leading and recognized provider of housekeeping
                cleaning products on all e-commerce platforms, extending our
                reach to every dealer and establishing Protexive Enterprises as
                a household name in the industry.
              </p>
            </div>
          </div>
          <div className="mission">
            <div className="mission-text">
              <i className="fas fa-bullseye"></i>
              <h2>Mission</h2>
              <p>
                To deliver top-quality, affordable, and safe cleaning solutions
                for institutional, industrial, and household use. We aim to
                continuously innovate and maintain the highest standards through
                rigorous quality control and a customer-centric approach,
                ensuring our products meet the evolving needs of our market and
                outshine our competitors.
              </p>
            </div>
            <div className="mission-image"></div>
          </div>
        </div>
        <div className="team-section">
          <h2>Meet Our Team</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="team-member">
                <div className="team-photo">
                  <img src="https://via.placeholder.com/150" alt="John Doe" />
                </div>
                <h3>Name surname</h3>
                <p>CEO & Founder</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-member">
                <div className="team-photo">
                  <img src="https://via.placeholder.com/150" alt="Jane Smith" />
                </div>
                <h3>Name surname</h3>
                <p>Operations Manager</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-member">
                <div className="team-photo">
                  <img
                    src="https://via.placeholder.com/150"
                    alt="Emily Johnson"
                  />
                </div>
                <h3>Name surname</h3>
                <p>Lead Cleaner</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonials-section">
          <h2>What Our Customers Say</h2>
          <div className="testimonial">
            <p>
              "Protexive has transformed our office space. Their attention to
              detail is unmatched!"
            </p>
            {/* <h4>- Michael Brown</h4> */}
          </div>
          <div className="testimonial">
            <p>
              "I love coming home to a clean house. The team is professional and
              thorough."
            </p>
            {/* <h4>- Sarah Wilson</h4> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
