import React from "react";
import "./TechnologyContent.css";
import bevrages from "../images/BREWERY.jpg";
import dairy from "../images/dairy.jpg";
import Poultry from "../images/poultry.jpg";
import food from "../images/food.jpg";

const TechnologyItem = ({ title, description, imageSrc, imageAlt }) => {
  return (
    <div className="technology-item">
      <div className="technology-image-container">
        <img src={imageSrc} alt={imageAlt} className="technology-image" />
      </div>
      <div className="technology-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Technologies = () => {
  const technologies = [
    {
      title: "Brewery & Beverage",
      description:
        "Providing specialized cleaning products to maintain hygiene and quality in beverage production.",
      imageSrc: bevrages,
    },
    {
      title: "Dairy",
      description:
        "Offering advanced cleaning solutions to ensure the safety and quality of dairy products.",
      imageSrc: dairy,
    },
    {
      title: "Poultry",
      description:
        "Supplying effective cleaning products to uphold rigorous safety standards in poultry processing.",
      imageSrc: Poultry,
    },
    {
      title: "Food & Snacks",
      description:
        "Delivering cleaning solutions that support the production of healthy and sustainable food products.",
      imageSrc: food,
    },
    {
      title: "Placeholder",
      description:
        "Ensuring product quality and consistency through testing and analysis.",
      imageSrc: "https://via.placeholder.com/300x200",
      imageAlt: "Analytical chemistry image",
    },
  ];

  return (
    <section className="technologies">
      <h2>Product Category</h2>
      <div className="technology-list">
        {technologies.map((technology, index) => (
          <TechnologyItem key={index} {...technology} />
        ))}
      </div>
    </section>
  );
};

export default Technologies;
