// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <section id="footer">
      <div className="container-footer">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <h5
              className="footer-link-headings foo-head-fix"
              style={{ color: "white" }}
            >
              Quick Links
            </h5>
            <ul className="list-unstyled">
              <li>
                <Link className="quick-link-items" to="/about-us">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="quick-link-items" to="/products">
                  Products
                </Link>
              </li>
              <li>
                <Link className="quick-link-items" to="/events">
                  Events
                </Link>
              </li>
              <li>
                <Link className="quick-link-items" to="/faqs">
                  FAQs
                </Link>
              </li>
              <li>
                <Link className="quick-link-items" to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-6">
            <h5
              className="footer-link-headings foo-head-fix"
              style={{ color: "white" }}
            >
              India
            </h5>
            <ul className="list-unstyled">
              <li>Protexive Enterprises PVT LTD</li>
              <li>Plot No.32/2, Swagat industrial,</li>
              <li>and logistics park, Near Virat alloy,</li>
              <li>chhatral-kadi highway,</li>
              <li>pin code 382729</li>
              <li>
                <a href="tel:+91 9428630931" className="contact-link">
                  +91 9428630931
                </a>
              </li>
              <li>
                <a href="mailto:info@protexive.in" className="contact-link">
                  info@protexive.in
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-6">
            <h5
              className="footer-link-headings foo-head-fix"
              style={{ color: "white" }}
            >
              Head Office Address
            </h5>
            <ul className="list-unstyled">
              <li>909, I Square Corporate Park</li>
              <li>Science City</li>
              <li>Ahmedabad 380060</li>
              <li>
                <a href="tel:+91 9428630931" className="contact-link">
                  +91 9428630931
                </a>
              </li>
              <li>
                <a href="mailto:info@protexive.in" className="contact-link">
                  info@protexive.in
                </a>
              </li>
            </ul>
          </div>
          <hr className="line-break-bottom" />
          <div className="row">
            <div className="col-lg-6">
              <p className="footer-text">
                © 2024 Protexive. All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
