import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-us__location">
        <h2>Our Location</h2>
        <div className="contact-us__details">
          <div className="contact-us__address">
            <p>India</p>
            <p>Protexive Enterprises PVT LTD</p>
            <p>Plot No.32/2, Swagat industrial,</p>
            <p>and logistics park, Near Virat alloy,</p>
            <p>chhatral-kadi highway,</p>
            <p>pin code 382729</p>
            <p>+91 9428630931</p>
            <p>info@protexive.in</p>
          </div>
          <div className="contact-us__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.819685628677!2d72.41812117454833!3d23.286000706447794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c237e99ab720f%3A0x31aa89aaf3321bb5!2sPROTEXIVE%20ENTERPRISES%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1720680157512!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="contact-us__separator"></div>
      <div className="contact-us__form">
        <h2>Get in touch with us</h2>
        <form>
          <div className="form-group">
            <label htmlFor="fullName">Full Name*</label>
            <input type="text" id="fullName" name="fullName" required />
          </div>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input type="text" id="companyName" name="companyName" />
          </div>
          <div className="form-group">
            <label htmlFor="mobileNumber">Mobile Number*</label>
            <input type="tel" id="mobileNumber" name="mobileNumber" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email*</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message*</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          <button type="submit">SEND MESSAGE</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
